/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.project {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  font-weight: 400;
  /* overflow: auto !important; */
}

main {
  background: #000000;
  /* background-image: url(https://i.imgur.com/SCquul8.png); */
 background-image:  url(https://raw.githubusercontent.com/gurupawar/portfolio/main/assets/blob-haikei.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
}

ul,
ol {
  list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
video {
  display: block;
  max-width: 100%;
}

.btn {
  border: 2px solid rgb(253, 3, 95);
  padding: 10px 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  outline: none;
}

.PrimaryBtn {
  background: rgb(253, 3, 95);
  color: #f6f6f6;
}

.PrimaryBtn:hover {
  background: #fff;
  color: #000000;
}

.SecondarBtn:hover {
  background: rgb(253, 3, 95);
  color: #fff;
}

.btn2 {
  margin-right: 1rem;
}

.Container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

.Containerr{
  margin-left: 100px;
}

.BigCard {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px;
  /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}

.AboutBio,
.tagline2 {
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
  color: #151418;
  line-height: 1.6;
}

.tagline2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.SectionTitle {
  font-size: 28px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 3.5rem;
}
.ProjectWrapper {
  margin-top: -1rem;
}

/* ------------------------------------ Media for Container */
@media (min-width: 576px) {
  .Container {
    max-width: 540px;
  }
  .Containerr{
    margin-left: 50px;
  }
}
@media (min-width: 768px) {
  .Container {
    max-width: 720px;
  }
  .ProjectWrapper {
    margin-top: -5rem;
  }
}
@media (min-width: 992px) {
  .Container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .Container {
    max-width: 1000px;
  }
}
